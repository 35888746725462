import React from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

import ME from "../assets/img/me.png"
import AA from "../assets/icons/certificate.png"
import Check from "../assets/icons/check.png"
import Customer from "../assets/icons/customer.png"


const AboutSection = () => {
    AOS.init();

    return (
        <div className="space-y-20  overflow-hidden " >
            <div className="flex justify-between xs:block sm:block md:block xs:space-y-6 sm:space-y-6 md:space-y-6">
                <div className="w-[30%] xs:w-[100%] sm:w-[100%] md:w-[100%] " data-aos-easing="ease-in-out" data-aos="fade-right" data-aos-once="false" data-aos-mirror="true"   >
                    <img src={ME} className="w-[100%]  max-w-[322px] m-auto" alt="" />
                </div>
                <div className="w-[65%] p-2  xs:w-[100%] sm:w-[100%] md:w-[100%] space-y-8" data-aos-easing="ease-in-out" data-aos="fade-left" data-aos-once="false" data-aos-mirror="true" data-aos-duration="300">
                    <div className="shadow w-[100%] py-8 px-8 rounded">
                        <h3 className="text-[32px] xs:text-[1.5rem] font-[600] font-[Unbounded] text-gray-800 md:text-center sm:text-center xs:text-center">Hello! This is Moin Uddin</h3>
                    </div>
                    <div>
                        <p className="text-gray-800 text-[16px] xs:text-[14px]  max-w-[700px] md:text-center sm:text-center xs:text-center" > Welcome to my website! I'm Moin Uddin, your dedicated professional Etsy virtual assistant specializing in brand building. With a proven track record, I've collaborated with over 90 Etsy shop brands, transforming them into profitable ventures. My expertise encompasses a comprehensive range of services, including meticulous market research, pinpointing lucrative niches, establishing brand identity, crafting compelling Etsy listings, optimizing SEO strategies, orchestrating ads, and executing effective marketing campaigns. Additionally, I offer insightful consultations for online income generation and passive revenue streams. If you're a small-to-medium manufacturer, artist, or home-based business, I can also leverage my skills to successfully market and sell your products on Etsy.
                        </p>
                    </div>
                    <div className="xs:flex xs:justify-center sm:flex sm:justify-center md:flex md:justify-center">
                        <button className="py-2 px-4 mt-3 text-[#DE9151] hover:rounded rounded border border-[#DE9151] shadow hover:shadow-md hover:bg-[#DE9151] hover:text-[#fef9f3] text-[16px] font-[500]">
                            <a href="https://www.fiverr.com/virtual390" target="_blank">Hire Me</a>
                        </button>

                    </div>
                </div>
            </div>
            <div className="grid p-2 xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 md:gap-y-4 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3    xs:space-y-6 sm:space-y-6   justify-around ">
                <div data-aos-easing="ease-in-out" data-aos="fade-down-right"  data-aos-once="false"  data-aos-mirror="true"  data-aos-duration="300" data-aos-anchor-placement="top-bottom" className=" w-[300px] xs:w-[100%] sm:w-[100%] lg:w-[270px]   shadow py-12 space-y-8 m-auto">
                    <div className=" bg-[#DE9151] w-[35%] xs:w-[100px]  sm:w-[120px]  flex justify-center py-4 m-auto">
                        <img src={AA} className="" alt="" />
                    </div>
                    <h3 className="text-[22px] xs:text-[18px] font-[600] text-center ">  Years of Experiences <br /> 8+</h3>

                </div>
                <div data-aos="fade-down"  data-aos-once="false"  data-aos-mirror="true"  data-aos-duration="300" data-aos-anchor-placement="top-bottom" className=" w-[300px] xs:w-[100%] sm:w-[100%] lg:w-[270px]   shadow py-12 space-y-8 m-auto">
                    <div className=" bg-[#DE9151] w-[35%] xs:w-[100px] sm:w-[120px]  flex justify-center py-4 m-auto">
                        <img src={Check} className="" alt="" />
                    </div>
                    <h3 className="text-[22px] xs:text-[18px] font-[600] text-center">  Projects Done <br />500+</h3>

                </div>
                <div data-aos="fade-down-left"  data-aos-once="false"  data-aos-mirror="true"  data-aos-duration="300" data-aos-anchor-placement="top-bottom" className=" w-[300px] xs:w-[100%] sm:w-[100%] lg:w-[270px]   shadow py-12 space-y-8 m-auto">
                    <div className=" bg-[#DE9151] w-[35%] xs:w-[100px] sm:w-[120px]  flex justify-center py-4 m-auto">
                        <img src={Customer} className="" alt="" />
                    </div>
                    <h3 className="text-[22px] xs:text-[18px] font-[600] text-center" >  Successful clients <br /> 400+</h3>

                </div>

            </div>
        </div>
    );
}

export default AboutSection;
