
import React from 'react';

import { BrowserRouter as Router, Navigate , Route, Routes, Link } from 'react-router-dom';
import Blog from './page/blog';
import Home from "./page/home"
import Navbar from './layout/navbar';
import WhatIdoSection from './compunents/whatido';
import EtsyShopBlog from './page/etsyshopblog';
import BoostYourEtsy from './page/boostesty';
import NotSuspend from './page/notsuspend';
import WaytoOptimize from './page/waystooptimize';
import ProductDeactivated from './page/productdeactivated';
import DoEtsySco from './page/doetsysco';
import EnoughSales from './page/enoughsales';
import FundsinReserve from './page/fundsinreserve';
import IncreaseSales from './page/increasesales';
import RankProducts from './page/rankproducts';
import RemoveBadReviews from './page/removebadreviews';
import PromoteEtsyProducts from './page/promoteEtsyproducts';
import StartDropshpping from './page/startdropshpping';
import ProductHunting from './page/producthunting';
import SetupPrint from './page/Setupprint';
import ShopGetSuspension from './page/shopgetsuspension';

const App = () => {
  return (
   

      
      <Routes>
        <Route path="/" exact element={<Navigate to="/home" />}  />
        <Route path="/home" exact element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        {/* <Route path="/home" element={<Home />} /> */}
        <Route path="/blog/how-to-create-an-etsy-shop" element={<EtsyShopBlog />} />
        <Route path="/blog/how-to-boost-your-etsy-sale" element={<BoostYourEtsy />} />
        <Route path="/blog/how-to-not-suspend-your-etsy-shop" element={<NotSuspend />} />
        <Route path="/blog/best-ways-to-optimize-your-etsy-ads" element={<WaytoOptimize />} />
        <Route path="/blog/why-is-my-product-deactivated-by-etsy" element={<ProductDeactivated />} />
        <Route path="/blog/how-to-do-etsy-seo-title-tags-and-description" element={<DoEtsySco />} />
        <Route path="/blog/why-is-your-etsy-shop-not-getting-enough-sales" element={<EnoughSales />} />
        <Route path="/blog/why-did-etsy-put-your-funds-in-reserve" element={<FundsinReserve />} />
        <Route path="/blog/How-to-increase-sales-on-an-Etsy-shop" element={<IncreaseSales />} />
        <Route path="/blog/How-to-rank-products-on-the-first-page-Etsy" element={<RankProducts />} />
        <Route path="/blog/can-we-remove-bad-reviews-on-Etsy" element={<RemoveBadReviews />} />
        <Route path="/blog/How-to-promote-Etsy-products-on-Etsy-shop" element={<PromoteEtsyProducts />} />
        <Route path="/blog/How-to-start-dropshpping-on-Etsy" element={<StartDropshpping />} />
        <Route path="/blog/product-hunting-and-supplier-finding-on-Etsy-shop" element={<ProductHunting />} />
        <Route path="/blog/Setup-print-on-demand-shop-on-Etsy" element={<SetupPrint />} />
        <Route path="/blog/Why-does-your-Etsy-shop-get-suspension-and-how-to-avoid-it" element={<ShopGetSuspension />} />
       


        
        
        

       
        
      </Routes>

  );
}

export default App;
