import React from "react";
import { TypeAnimation } from 'react-type-animation';
import up from "../assets/icons/fv.png"
import fv from "../assets/icons/wp.png"


const HeroSection = () => {
    return (
        <div className="">
            <div className="">
                <div className="">
                    <section className="hero">
                        <div className="content text-center space-y-5">
                            <h2 className="text-[4.5rem] xs:text-[2.4rem]">ETSY EXPERT</h2>
                            <p>Unique products, creative crafts, and personalized items for a successful e-Commerce venture in the handmade marketplace.</p>
                            <div className="flex justify-center space-x-3">
                               <a className="transition duration-300 delay-150 hover:delay-300" target="_blank" href="https://www.fiverr.com/virtual390">
                               <img  className="shodow hover:w-[45px]   " src={up} alt="" />
                               </a>
                               <a  target="_blank" href="https://www.upwork.com/freelancers/~01dcb01d1bb92eccf9" >
                               <img className="hover:w-[45px] " src={fv} alt="" />
                               </a>
                            </div>
                        </div>
                        
                        <div className="waves"></div>
                    </section>


                </div>
            </div>
        </div>
    );
}

export default HeroSection;
