import React, { useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import WhatIdoCard from "./whatIdocard";
import Pro1 from "../assets/img/11.PNG"
import Pro2 from "../assets/img/1.png"
import Pro3 from "../assets/img/4.png"
import Pro4 from "../assets/img/6.png"


const ProjectSection = () => {
    AOS.init();


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const [isModalOpen3, setIsModalOpen3] = useState(false);
    const [isModalOpen4, setIsModalOpen4] = useState(false);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const toggleModal2 = () => {
        setIsModalOpen2(!isModalOpen2);
    };
    const toggleModal3 = () => {
        setIsModalOpen3(!isModalOpen3);
    };
    const toggleModal4 = () => {
        setIsModalOpen4(!isModalOpen4);
    };

    return (
        <div className="space-y-4 overflow-hidden p-2">
            <div className="flex justify-center ">
                <div data-aos="fade-down"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className=" text-center bgimg shadow w-[100%] py-8 px-8  space-y-3">
                    <h3 className="text-[32px] xs:text-[1.5rem] font-[700] text-gray-800 tracking-[2px] m-auto w-[22%] xs:w-[180px] sm:w-[150px] md:w-[150px] border-b border-[#DE9151] font-[Unbounded]">Projects</h3>
                    {/* <hr className="w-[170px] m-auto h-[3px] bg-[#DE9151]"/> */}
                    <p className="text-[16px] font-[500] text-gray-800">Exemplary showcase of multifaceted skills driving successful project accomplishments.</p>
                </div>


            </div>
            <div className="py-4">
                <div className="flex justify-between py-8 xs:block sm:block md:block xs:space-y-8 sm:space-y-8 md:space-y-8 ">
                    <div onClick={toggleModal}  data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className="shadow w-[48%] xs:w-[100%] sm:w-[100%] md:w-[100%] hover:shadow-xl cursor-pointer p-1">
                        <img src={Pro1} alt="" className="" />
                    </div>
                    {isModalOpen && (
                        <div
                            id="popup-modal"
                            tabIndex="-1"
                            className="fixed top-10 left-0 right-0 z-50 flex items-center justify-center w-full h-screen p-4 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50"
                        >
                            <div className="w-full">
                                <div className="relative bg-white rounded-lg shadow ">

                                    <button
                                        onClick={toggleModal}
                                        type="button"
                                        className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center "
                                    >
                                        <svg
                                            className="w-3 h-3"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 14 14"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                            />
                                        </svg>
                                        <span className="sr-only">Close modal</span>
                                    </button>

                                    <div>
                                        <img src={Pro1} alt="" className="h-[550px] xs:h-[auto] sm:h-[auto] md:h-[auto]" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div onClick={toggleModal2}  data-aos="fade-down-left"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom"  className="shadow w-[48%] xs:w-[100%] sm:w-[100%] md:w-[100%] hover:shadow-xl cursor-pointer p-1">
                        <img src={Pro2} alt="" className="" />
                    </div>
                    {isModalOpen2 && (
                        <div
                            id="popup-modal"
                            tabIndex="-1"
                            className="fixed top-10 left-0 right-0 z-50 flex items-center justify-center w-full h-screen p-4 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50"
                        >
                            <div className="w-full">
                                <div className="relative bg-white rounded-lg shadow ">

                                    <button
                                        onClick={toggleModal2}
                                        type="button"
                                        className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center "
                                    >
                                        <svg
                                            className="w-3 h-3"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 14 14"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                            />
                                        </svg>
                                        <span className="sr-only">Close modal</span>
                                    </button>

                                    <div>
                                        <img src={Pro2} alt="" className="h-[550px] xs:h-[auto] sm:h-[auto] md:h-[auto]" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="flex justify-between xs:block sm:block md:block xs:space-y-8 sm:space-y-8 md:space-y-8 ">
                    <div onClick={toggleModal3}  data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom"  className="shadow w-[48%] xs:w-[100%] sm:w-[100%] md:w-[100%] hover:shadow-xl cursor-pointer p-1">
                        <img src={Pro3} alt="" className="" />
                    </div>
                    {isModalOpen3 && (
                        <div
                            id="popup-modal"
                            tabIndex="-1"
                            className="fixed top-10 left-0 right-0 z-50 flex items-center justify-center w-full h-screen p-4 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50"
                        >
                            <div className="w-full">
                                <div className="relative bg-white rounded-lg shadow ">

                                    <button
                                        onClick={toggleModal3}
                                        type="button"
                                        className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center "
                                    >
                                        <svg
                                            className="w-3 h-3"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 14 14"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                            />
                                        </svg>
                                        <span className="sr-only">Close modal</span>
                                    </button>

                                    <div>
                                        <img src={Pro3} alt="" className="h-[550px] xs:h-[auto] sm:h-[auto] md:h-[auto]" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div onClick={toggleModal4}  data-aos="fade-down-left"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom"  className="shadow w-[48%] xs:w-[100%] sm:w-[100%] md:w-[100%] hover:shadow-xl cursor-pointer p-1">
                        <img src={Pro4} alt="" className="" />
                    </div>
                    {isModalOpen4 && (
                        <div
                            id="popup-modal"
                            tabIndex="-1"
                            className="fixed top-10 left-0 right-0 z-50 flex items-center justify-center w-full h-screen p-4 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50"
                        >
                            <div className="w-full">
                                <div className="relative bg-white rounded-lg shadow ">

                                    <button
                                        onClick={toggleModal4}
                                        type="button"
                                        className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center "
                                    >
                                        <svg
                                            className="w-3 h-3"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 14 14"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                            />
                                        </svg>
                                        <span className="sr-only">Close modal</span>
                                    </button>

                                    <div>
                                        <img src={Pro4} alt="" className="h-[550px] xs:h-[auto] sm:h-[auto] md:h-[auto]" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

            </div>


        </div>
    );
}

export default ProjectSection;
