import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import Blg1 from "../assets/img/blg1.jpg"
import pd from "../assets/img/pd.png"




const SetupPrint = () => {
    AOS.init();



    return (
        <div className="" >
            <div className="py-20 xs:py-6 sm:py-10 w-[80%] m-auto max-w-[1200px] space-y-12">
                <div data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className='space-y-8 '>
                    
                    <img className='w-[100%]' src={pd} alt="" />
                </div>
                <div className='pb-4 space-y-8'>
                    <h3 data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className='text-[22px] font-[600]  text-gray-700'>Setup print on demand shop on Etsy.</h3>
                    <p data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className='text-[16px] font-[400] text-gray-800'>
                        Setting up a print-on-demand (POD) business on Etsy involves creating and listing products that are created and shipped to customers by a third-party POD provider. Here's a step-by-step guide to help you get started: <br /><br />

                        <span className='font-[600]'> *Step 1: Research and Niche Selection*</span> <br /><br />

                        <span className='font-[600]'>1. *Choose a Niche:*</span>  Decide on the niche or theme for your POD business. This could be anything from apparel to home decor, accessories, or personalized gifts.  <br /><br />

                        <span className='font-[600]'>2. *Market Research:*</span>  Research your chosen niche to understand customer preferences, trends, and potential competition. Identify unique selling points that can set your products apart.  <br /><br />

                        <span className='font-[600]'> *Step 2: Select a POD Provider*</span>   <br /><br />

                        <span className='font-[600]'> 1. *Research Providers:*</span>  Research and select a reputable POD provider that aligns with your chosen niche. Popular POD platforms include Printful, Printify, and Teespring.  <br /><br />

                        <span className='font-[600]'>  2. *Sign Up:*</span>  Create an account on the chosen POD platform and connect it to your Etsy shop.  <br /><br />

                        <span className='font-[600]'>*Step 3: Design Creation*</span>  <br /><br />

                        <span className='font-[600]'> 1. *Design Products:*</span>  Create or source designs for your products. These could include graphics, illustrations, typography, or any artwork that suits your niche.  <br /><br />

                        <span className='font-[600]'>  2. *Templates:*</span> Many POD platforms offer templates for various products. Use these templates to ensure your designs fit the dimensions and requirements of each product. <br /><br />

                        <span className='font-[600]'>*Step 4: Set Up Your Etsy Shop*</span> <br /><br />

                        <span className='font-[600]'>  1. *Create an Etsy Account:* </span>If you don't already have one, create an Etsy seller account. <br /><br />

                        <span className='font-[600]'>  2. *Shop Name and Branding:*</span>  Choose a shop name and set up your shop's branding, including a logo and banner. <br /><br />

                        <span className='font-[600]'>  3. *Shop Policies:*</span>  Set up your shop policies, including shipping, returns, and payment information. <br /><br />

                        <span className='font-[600]'>*Step 5: List Your POD Products on Etsy*</span>  <br /><br />

                        <span className='font-[600]'> 1. *Product Listings:*</span>  Create listings for your POD products on Etsy. Each listing should include high-quality images, a detailed description, pricing, and relevant tags. <br /><br />

                        <span className='font-[600]'>2. *Title and Description:*</span>  Optimize your listing titles and descriptions with relevant keywords to improve visibility in search results. <br /><br />

                        <span className='font-[600]'>3. *Variations:*</span>  If your products have variations (e.g., different sizes, colors), set them up correctly within each listing. <br /><br />

                        <span className='font-[600]'>  *Step 6: Pricing and Profit Margin*</span>  <br /><br />

                        <span className='font-[600]'> 1. *Pricing Strategy:*</span>  Calculate your pricing by factoring in the POD provider's base cost, Etsy fees, and your desired profit margin. Ensure that your prices are competitive in your niche. <br /><br />

                        <span className='font-[600]'>*Step 7: Order Fulfillment*</span>  <br /><br />

                        <span className='font-[600]'>1. *Order Placement:*</span>  When a customer places an order in your Etsy shop, the order details are automatically sent to the POD provider. <br /><br />

                        <span className='font-[600]'> 2. *Production and Shipping:*</span>  The POD provider will create and ship the product directly to the customer. You won't need to handle inventory or shipping. <br /><br />

                        <span className='font-[600]'> *Step 8: Customer Service*</span>  <br /><br />

                        <span className='font-[600]'>1. *Communication:*</span>  Stay in touch with customers regarding order status, shipping, and any inquiries they may have. <br /><br />

                        <span className='font-[600]'> *Step 9: Marketing and Promotion*</span> <br /><br />

                        1. <span className='font-[600]'> *Etsy SEO:*</span>  <span className='font-[600]'></span>  Optimize your product listings for Etsy's search algorithm by using relevant keywords and tags. <br /><br />

                        2.  <span className='font-[600]'>*Social Media:*</span>  Promote your products on social media platforms to attract potential buyers. <br /><br />

                        3. <span className='font-[600]'> *Paid Advertising:*</span>  Consider using Etsy's promoted listings or other advertising methods to increase your shop's visibility. <br /><br />

                        Starting a POD business on Etsy can be a rewarding venture, but it requires careful planning, design creativity, and effective marketing. As your shop grows, monitor customer feedback, track sales data, and adapt your strategies to continuously improve your offerings and customer experience.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default SetupPrint;
