import React from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import WhatIdoCard from "./whatIdocard";


const WhatIdoSection = () => {
    AOS.init();

    return (
        <div className="space-y-6 overflow-hidden " >
         <div data-aos="fade-down"  data-aos-once="false"  data-aos-mirror="true"  data-aos-duration="300" data-aos-anchor-placement="top-bottom" className="p-2 flex justify-center">
            <div className=" text-center bgimgService shadow w-[100%] py-8 px-8 space-y-3">
                <h3 className="text-[32px] xs:text-[1.2rem] font-[700] text-gray-800 tracking-[2px] m-auto w-[22%] xs:w-[150px] sm:w-[150px] md:w-[150px] border-b border-[#DE9151] font-[Unbounded]">Services</h3>
                {/* <hr className="w-[170px] m-auto h-[3px] bg-[#DE9151]"/> */}
                <p className="text-[16px] font-[500] text-gray-800">Tailored solutions meeting diverse needs with expertise, precision, and client-focused commitment. </p>
            </div>
           
         </div>
         
         <div className="">
            <WhatIdoCard />
         </div>
        </div>
    );
}

export default WhatIdoSection;
