import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';


import ed from "../assets/img/ed.png"




const ProductDeactivated = () => {

    AOS.init();

    return (
        <div className="" >
            <div className="py-20 xs:py-6 sm:py-10 w-[80%] m-auto max-w-[1200px] space-y-12">
                <div data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className='space-y-8 '>
                   
                    <img className='w-[100%]' src={ed} alt="" />
                </div>
                <div className='pb-4 space-y-8'>
                    <h3 data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className='text-[22px] font-[600] text-gray-700'>Why is my product deactivated by Etsy? Avoid Etsy deactivation and suspension</h3>
                    <p data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className='text-[16px] font-[400] text-gray-800'>
                        Etsy may deactivate listings or suspend seller accounts for various reasons, including violations of their policies, copyright infringement, selling prohibited items, and more. If your listing has been deactivated or if you're concerned about avoiding suspension, here's what you can do: <br /><br />

                        *1. **Why Listings Get Deactivated:*
                        - *Policy Violations:* Listings that violate Etsy's policies, such as selling counterfeit items, violating intellectual property rights, or engaging in prohibited activities, can lead to deactivation.
                        - *Quality Standards:* If your products do not meet Etsy's quality standards, they may deactivate your listings.
                        - *Misrepresentation:* If your listings include inaccurate or misleading information, they could be deactivated.
                        - *Copyright or Trademark Issues:* If your listings infringe on the rights of others, Etsy might take them down. <br /><br />

                        *2. **How to Reactivate Listings:*
                        - Log in to your Etsy seller account.
                        - Go to "Shop Manager."
                        - Select "Listings" from the left sidebar.
                        - Find the deactivated listing and click on it.
                        - Review the reason for deactivation and make necessary changes to bring the listing into compliance with Etsy's policies.
                        - Click the "Activate" button to reactivate the listing. <br /><br />

                        *3. **Avoiding Etsy Seller Account Suspension:*
                        - *Read and Understand Policies:* Familiarize yourself with Etsy's Seller Policy, Listing Policy, and Intellectual Property Policy to ensure that your listings and activities comply with their guidelines.
                        - *Accurate Listings:* Provide accurate and truthful information in your listings, including descriptions, prices, and product details.
                        - *Original Content:* Use original photos and content in your listings. Avoid using copyrighted images or text without permission.
                        - *Quality Assurance:* Ensure that your products meet Etsy's quality standards before listing them for sale.
                        - *Prohibited Items:* Avoid selling prohibited items, including illegal items, drugs, weapons, and items that violate intellectual property rights.
                        - *Prompt Customer Service:* Respond promptly to customer inquiries and address any issues or concerns they may have.
                        - *Shipping and Handling:* Clearly communicate your shipping policies and estimated delivery times. Fulfill orders in a timely manner.
                        - *Customer Feedback:* Aim to maintain a positive feedback rating by providing excellent customer service and resolving issues professionally. <br /><br />

                        If you're concerned about your Etsy seller account's status or have received warnings, it's a good idea to review Etsy's policies thoroughly and make any necessary adjustments to your shop. Adhering to the platform's guidelines will help you avoid deactivations and suspensions, ensuring a positive selling experience for both you and your customers.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default ProductDeactivated;
