import React from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import AA from "../assets/icons/aa.png"
import CL from "../assets/icons/cl.png"
import PH from "../assets/icons/ph.png"
import SP from "../assets/icons/sp.png"
import Ag from "../assets/icons/ag.png"
// import SP from "../assets/icons/sp.png"
const WhatIdoData = [
  {
    id: 1,
    icon: AA,
    titel: "Etsy Account Creation",
    para: "I'll help craft your Etsy shop for free. Lets create!",
    tagLink: "https://www.fiverr.com/virtual390/audit-your-etsy-shop-and-revamp-your-etsy-shop?context_referrer=user_page&ref_ctx_id=983f5d0d417cead097bfad0872162bbc&pckg_id=1&pos=5&imp_id=d5b4c741-4849-4224-bc85-af205bdc8399"
  },
  {
    id: 2,
    icon: CL,
    titel: "Product Listing, Title, Tags and SEO ",
    para: "I'll handle Product Listing: titles, tags, descriptions, and SEO using relevant keywords at $5 per product.",
    tagLink: "https://www.fiverr.com/virtual390/manage-your-business-books-on-xero-accounting-software?context_referrer=user_page&ref_ctx_id=983f5d0d417cead097bfad0872162bbc&pckg_id=1&pos=1&imp_id=a272e5ce-c40b-4f18-bec0-7a471a2d2a46"
  },
  {
    id: 3,
    icon: Ag,
    titel: "Niche Finding And Supplier Hunting",
    para: "Seeking niches, sourcing products, and finding suppliers, all at $5 per product.",
    tagLink: "https://www.fiverr.com/virtual390/manage-your-business-books-on-xero-accounting-software?context_referrer=user_page&ref_ctx_id=983f5d0d417cead097bfad0872162bbc&pckg_id=1&pos=1&imp_id=a272e5ce-c40b-4f18-bec0-7a471a2d2a46"
  },
  {
    id: 4,
    icon: SP,
    titel: "Product Ads And Promotion",
    para: "I offer Etsy product advertising and individual product promotion for $3 each. Boost your Etsy items effectively.",
    tagLink: "https://www.fiverr.com/virtual390/etsy-seo-etsy-listing-etsy-setup-rank-promotion-etsy-account-e416?context_referrer=user_page&ref_ctx_id=983f5d0d417cead097bfad0872162bbc&pckg_id=1&pos=3&imp_id=39c50108-40e1-44ce-84f7-314558284859"
  },

]

const WhatIdoCard = () => {
  AOS.init();
  return (
    <div className=" py-6 px-2   grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 md:gap-y- lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4  gap-3 xs:space-y-6 sm:space-y-6 ">
      {
        WhatIdoData.map((item, id) => (
          <>
            <div data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className="shadow hover:shadow-xl cursor-pointer py-6 px-6 xs:w-[100%] sm:w-[100%] lg:w-[100%]  md:w-[100%] max-w-[300px] m-auto text-center space-y-4 rounded h-[370px] flex justify-between flex-col  max-h-[400px]">
              <div className="space-y-4 ">
                <div className="flex justify-center ">
                  <div className=" bg-[#DE9151] p-4 rounded">
                    <img src={item.icon} alt="" />
                  </div>
                </div>
                <h3 className="text-[18px] text-gray-800 font-[600]">{item.titel}</h3>
                <div className="">
                  <h6 className="text-[15px] text-gray-800 font-[500]">{item.para}</h6>
                </div>
              </div>
              <div>
                <button className="bg-[#DE9151]  text-[#fef9f3] border hover:delay-150 transition duration-300 border-[#fef9f3] py-2 rounded hover:text-[#DE9151] hover:bg-[#fef9f3] hover:border-[#DE9151] px-3"> <a href={item.tagLink} target="blank">Buy Now </a></button>
              </div>
            </div>
          </>
        ))
      }
    </div>
  );
}

export default WhatIdoCard;
