
import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import fd from "../assets/img/fd.png"



const FundsinReserve = () => {
    AOS.init();


    return (
        <div className="" >
            <div className="py-20 xs:py-6 sm:py-10 w-[80%] m-auto max-w-[1200px] space-y-12">
                <div data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="600" data-aos-anchor-placement="top-bottom" className='space-y-8 '>
                    
                    <img className='w-[100%]' src={fd} alt="" />
                </div>
                <div className='pb-4 space-y-8'>
                    <h3 data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className='text-[22px] font-[600]  text-gray-700'>Why did Etsy put your funds in reserve and its solution.</h3>
                    <p data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className='text-[16px] font-[400] text-gray-800'>
                        Etsy may put funds from your sales into a reserve for a variety of reasons, including to mitigate potential risks, address payment processing issues, or manage chargebacks and refunds. Reserves are meant to protect both buyers and sellers and ensure the stability of the marketplace. If your funds are in reserve, it's important to follow Etsy's guidelines and policies to address the situation.<br /><br />

                        Here are some steps you can take if you want to potentially withdraw funds from your Etsy reserves before the standard 45-day period:<br /><br />

                        1. <span className='font-[600]'>*Review Etsy's Policies:*</span> Understand why your funds are in reserve by carefully reading Etsy's policies. Check your seller dashboard for any notifications or messages related to the reserve status.<br /><br />

                        2. <span className='font-[600]'>*Provide Accurate Information:*</span> Ensure that your shop's information, including contact details, bank account information, and shipping policies, is accurate and up-to-date. Inaccurate information can lead to reserves or delays.<br /><br />

                        3. <span className='font-[600]'>*Maintain Positive Selling Metrics:*</span> Consistently provide excellent customer service, promptly ship orders, and respond to buyer inquiries. Good selling metrics can help reduce the likelihood of your funds being placed in reserve.<br /><br />

                        4.<span className='font-[600]'>*Clear Communication:*</span>  If you believe there was a mistake or if you've resolved any issues related to the reserve, reach out to Etsy's customer support. Use the "Help" or "Contact Us" sections on the platform to explain your situation and seek assistance.<br /><br />

                        5. <span className='font-[600]'> *Provide Documentation:*</span>  If Etsy requests additional information or documentation to verify your identity, ownership of the shop, or any other relevant details, make sure to promptly provide the required documents.<br /><br />

                        6.   <span className='font-[600]'> *Monitor Your Dashboard:*</span> Regularly check your seller dashboard for updates, notifications, and messages from Etsy. They may provide instructions or information about the reserve status.
                        <br /><br />
                        7.  <span className='font-[600]'> *Avoid Violating Etsy Policies:*</span> Ensure that your shop and listings comply with Etsy's policies. Any policy violations can lead to reserves or account restrictions.<br /><br />

                        8. <span className='font-[600]'> *Build a Positive Selling History:*</span> Over time, as you establish a positive selling history on Etsy and gain trust as a reliable seller, the likelihood of having funds placed in reserve may decrease.<br /><br />

                        9. <span className='font-[600]'> *Professional Account:*</span> If you have a Professional Seller account on Etsy, this might affect reserve policies. Check if any differences apply to your account type. <br /><br />

                        It's important to note that while Etsy allows you to appeal reserve decisions, there might be cases where the reserve period cannot be shortened. If you're uncertain about the specifics of your situation, it's best to directly communicate with Etsy's customer support for personalized assistance and guidance.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default FundsinReserve;
