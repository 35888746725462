import React, { useRef, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import icon1 from "../assets/icons/am.png"
import icon2 from "../assets/icons/et.png"
import icon3 from "../assets/icons/wm.png"
import icon4 from "../assets/icons/eb.png"
import icon5 from "../assets/icons/ae.png"

const iconData = [
    {
        id: 1,
        icon: icon1,
        title:"Amozan",
    },
    {
        id: 2,
        icon: icon2,
        title:"Etsy",

    },
    {
        id: 3,
        icon: icon3,
        title:"Walmart",

    },
    {
        id: 4,
        icon: icon4,
        title:"Ebay",

    },
    {
        id: 5,
        icon: icon5,
        title:"Ali Express",

    },

]

const MarketPlaceSection = () => {
    AOS.init();

    return (

            <div className="  overflow-hidden p-2 py-4 grid grid-cols-5 md:grid-cols-3 md:gap-y-8  sm:grid-cols-2 sm:gap-8  xs:grid-cols-1 xs:gap-y-8 ">
                {
                    iconData.map((item) => (
                        <div  data-aos="fade-up"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom"  className='shadow p-8 rounded space-y-2 w-[160px] m-auto sm:w-[100%] xs:w-[100%]'>
                           <div className='flex justify-center'>
                           <img src={item.icon} alt="" />
                           </div>
                            <h3 className='text-center'>{item.title}</h3>
                        </div>
                    ))
                }
            </div>
   
    );
}

export default MarketPlaceSection;
