import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import Navbar from "./navbar/index";
import App from "../App";
import Footer from "./footer/index";

const Layout = () => {
  return (
    <div className="">
      <Router>
        <div className="">
          <div className="z-[999] relative">
            <Navbar />
          </div>

          <div className="pt-[4.5rem] xs:pt-[2rem] sm:pt-[2rem] md:pt-[2rem]">
            <App />
          </div>
          <div className="">
            <Footer />
          </div>
        </div>
      </Router>
    </div>
  );
}

export default Layout;
