import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import BlogCard from '../../compunents/blogcard';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';



import img1 from "../../assets/img/img1.png"
import IMG from "../../assets/img/img1.png"
import IMGs from "../../assets/img/sus.jpg"
import ads from "../../assets/img/ads.png"
import sp from "../../assets/img/sp.png"
import ed from "../../assets/img/ed.png"
import sco from "../../assets/img/sco.png"
import ph from "../../assets/img/ph.png"
import pd from "../../assets/img/pd.png"
import bs from "../../assets/img/bs.png"
import pep from "../../assets/img/pep.png"
import es from "../../assets/img/es.png"
import ep from "../../assets/img/ep.png"
import fd from "../../assets/img/fd.png"
import er from "../../assets/img/er.png"
import eds from "../../assets/img/eds.png"
import enc from "../../assets/img/enc.png"









const Blog = () => {
  AOS.init();
  const BlogData = [
    {
      id: 1,
      title: "How to boost your Etsy sale?",
      para: "If you're wondering how to boost Etsy sales, it's essential to understand that learning how to boost Etsy sales requires a multifaceted approach. First and foremost, optimizing your product listings is crucial.  ",
      link: "how-to-boost-your-etsy-sale",
      cardImg:bs,
    },
    {
      id: 2,
      title: "How to not suspend your Etsy shop? Avoid mistakes in your Etsy shop.",
      para: "There are a few things you may do to have your suspended Etsy account reinstated, including fixing the problems that led to the suspension and contacting Etsy's support team.",
      link:"how-to-not-suspend-your-etsy-shop",
      cardImg:sp,
    },
    {
      id: 3,
      title: "Best ways to optimize your Etsy ads.",
      para: "Optimizing your Etsy ads involves a combination of strategies aimed at improving the performance of your ad campaigns and maximizing your return on investment (ROI)....",
      link:"best-ways-to-optimize-your-etsy-ads",
      cardImg:ads,
    },
    {
      id: 4,
      title: "Why is my product deactivated by Etsy? Avoid Etsy deactivation and suspension",
      para: "Etsy may deactivate listings or suspend seller accounts for various reasons, including violations of their policies, copyright infringement, selling prohibited items, and more. ",
      link:"why-is-my-product-deactivated-by-etsy",
      cardImg:ed,
    },
    {
      id: 5,
      title: "How to do Etsy SEO? Title tags and description",
      para: "Writing optimized titles and descriptions for your Etsy listings is essential for improving visibility and attracting potential buyers. Here's a guide on how to do it effectively:",
      link:"how-to-do-etsy-seo-title-tags-and-description",
      cardImg:sco,
    },
    {
      id: 6,
      title: "Why is your Etsy shop not getting enough sales? How to get sales on Etsy ?",
      para: "There could be several reasons why you're not getting good sales on Etsy. Selling on Etsy requires a combination of factors, from product quality and visibility to ",
      link:"why-is-your-etsy-shop-not-getting-enough-sales",
      cardImg:es,
    },
    {
      id: 7,
      title: "Why did Etsy put your funds in reserve and its solution.",
      para: "Etsy may put funds from your sales into a reserve for a variety of reasons, including to mitigate potential risks, address payment processing issues, or manage chargebacks and refunds.",
      link:"why-did-etsy-put-your-funds-in-reserve",
      cardImg:fd,
    },
    {
      id: 8,
      title: "How to increase sales on an Etsy shop?",
      para: "Increasing sales on Etsy requires a combination of effective strategies, dedication, and a willingness to adapt. Here are some actionable tips to help you boost your sales on Etsy:",
      link:"How-to-increase-sales-on-an-Etsy-shop",
      cardImg:enc,
    },
    {
      id: 9,
      title: "How to rank products on the first page Etsy?",
      para: "Dealing with fierce competition on Etsy requires a combination of strategic planning, differentiation, and effective marketing. ",
      link:"How-to-rank-products-on-the-first-page-Etsy",
      cardImg:ep,
    },
    {
      id: 10,
      title: "Can we remove bad reviews on Etsy ? How to score quality on Etsy?",
      para: "Negative reviews can be disappointing, but they also present an opportunity to showcase your excellent customer service and willingness to address issues.",
      link:"can-we-remove-bad-reviews-on-Etsy",
      cardImg:er,

    },
    {
      id: 11,
      title: "How to promote Etsy products on Etsy shop?",
      para: "Effective marketing and promotion are essential for gaining visibility and driving sales on Etsy. Here are some strategies to help you market and promote your Etsy shop successfully:",
      link:"How-to-promote-Etsy-products-on-Etsy-shop",
      cardImg:pep,
    },
    {
      id: 12,
      title: "How to start dropshpping on Etsy?",
      para: "Dropshipping on Etsy involves selling products without holding inventory. When a customer places an order, you purchase the item from a third-party supplier who then ships it directly to the customer",
      link:"How-to-start-dropshpping-on-Etsy",
      cardImg:eds,
    },
    {
      id: 13,
      title: "product hunting and supplier finding on Etsy shop.",
      para: "When looking for suppliers for dropshipping on Etsy, it's important to choose reliable and reputable partners that offer quality products and timely shipping. Keep in mind that Etsy ",
      link:"product-hunting-and-supplier-finding-on-Etsy-shop",
      cardImg:ph,
    },
    {
      id: 14,
      title: "Setup print on demand shop on Etsy.",
      para: "Setting up a print-on-demand (POD) business on Etsy involves creating and listing products that are created and shipped to customers by a third-party POD provider.",
      link:"Setup-print-on-demand-shop-on-Etsy",
      cardImg:pd,
    },
    {
      id: 15,
      title: "Why does your Etsy shop get suspension and how to avoid it?",
      para: "Avoiding policy violations on Etsy is crucial to maintaining a successful and compliant shop. Here are some tips to help you avoid violating Etsy's policies:",
      link:"Why-does-your-Etsy-shop-get-suspension-and-how-to-avoid-it",
      cardImg:IMGs,
    },
  ]

  return (
    <div className="py-20 w-[80%] overflow-hidden m-auto max-w-[1200px] space-y-10 " >
      <div className="p-2">
        <div className=" bgimgService pb-10 shadow rounded p-8 space-y-3">
          <h3 className="text-[42px] font-[Unbounded] font-[600] xs:text-[22px] sm:text-[32px] text-gray-700  tracking-[2px] xs:leading-[30px] sm:leading-[35px] leading-[40px]  " data-aos="fade-left" data-aos-easing="ease-in-out"  data-aos-once="false"  data-aos-mirror="true">Learn more about <span className='text-[#de9151]'>Etsy</span> </h3>

        </div>

      </div>
      <div className='p-2'>
        <Link to="how-to-create-an-etsy-shop">
          <div className='shadow-lg hover:shadow-xl ' data-aos-easing="ease-in-out" data-aos="fade-right"  data-aos-once="false"  data-aos-mirror="true" >
            <a
              href=""
              className="flex flex-col items-center bg-white border border-gray-200 rounded-lg  "
            >
              <img
                className="object-cover w-full rounded-t-lg h-96  md:rounded-none md:rounded-l-lg"
                src={IMG}
                alt=""
              />
              <div className="flex flex-col justify-between p-8 xs:p-5 leading-normal">
                <h5 className="mb-2 text-2xl xs:text-[18px] xs:leading-6 font-bold tracking-tight text-gray-700 dark:text-white"  >
                  How to create an etsy Shop?
                </h5>
                <p className="mb-3 font-normal xs:text-[14px] text-gray-700">
                  While opening an Etsy shop is a simple procedure, there are a few obstacles you may encounter. Follow this detailed tutorial to open an Etsy shop and avoid the most frequent pitfalls:
                  How to Open an Etsy Shop in 7 Easy Steps
                </p>
                <a
                  href="#"
                  className="w-[130px] inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-[#DE9151] rounded-lg hover:bg-[white] hover:border hover:border-[#DE9151] hover:text-[#DE9151] focus:ring-4 "
                >
                  Read more
                  <svg
                    className="w-3.5 h-3.5 ml-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M1 5h12m0 0L9 1m4 4L9 9"
                    />
                  </svg>
                </a>
              </div>
            </a>
          </div>
        </Link>
      </div>
      <div className=" p-2 grid grid-cols-3 xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-y-8 gap-x-6" >
        {
          BlogData.map((item) => (
            <div>
              <BlogCard title={item.title} para={item.para} link={item.link} img={item.cardImg} />
            </div>

          ))
        }

      </div>
    </div>
  );
}

export default Blog;
