import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

import AboutSection from '../../compunents/about';
import HeroSection from '../../compunents/heroSection';
import WhatIdoSection from '../../compunents/whatido';
import ProjectSection from '../../compunents/projects';
import SkilsSection from '../../compunents/skils';
import TestimonialsSection from '../../compunents/testimonialsSection';
import MarketPlaceSection from '../../compunents/marketplace';

const Home = () => {


    const { hash } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (hash) {
      const target = document.querySelector(hash);
      if (target) {
        target.scrollIntoView({ behavior: "smooth" });
        // Remove the hash from the URL to avoid conflicts with React Router
        navigate({ hash: null });
      }
    }
  }, [hash, navigate]);

    return (
        <div className="">
            <div>
                <p id="hero"></p>
                <HeroSection />
                <p id="about"></p>
            </div>
            <div className='space-y-28 py-24 w-[80%] m-auto max-w-[1200px]' >
                <div >
                   
                        <AboutSection />
                 
                    <div id="about" >

                    </div>
                    <p id="service"></p>
                </div>
                <div >
                    <WhatIdoSection />
                </div>

                <div>
                    <ProjectSection />

                </div>
                <div>
                    <MarketPlaceSection />
                    <p id='skill'></p>
                </div>
                <div>
                    <SkilsSection />
                </div>
                <div>
                    <TestimonialsSection />
                </div>

            </div>
        </div>
    );
}

export default Home;
