import React from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';


const SkilsSection = () => {
    AOS.init();

    return (
        <div className="space-y-4 p-2  overflow-hidden ">
            <div className="flex justify-center">
                <div  data-aos="fade-down"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className=" text-center bgimgskill shadow w-[100%] py-8 px-8 space-y-3">
                    <h3 className="text-[32px] xs:text-[1.2rem] font-[700] text-gray-800 tracking-[2px] m-auto w-[15%] xs:w-[180px] sm:w-[150px] md:w-[150px] border-b border-[#DE9151] font-[Unbounded]">Skills</h3>
                    {/* <hr className="w-[170px] m-auto h-[3px] bg-[#DE9151]"/> */}
                    <p className="text-[16px] font-[500] text-gray-800">A compilation highlighting a diverse range of competencies, from technical prowess to creative finesse.</p>
                </div>


            </div>
            <div className=" flex shadow justify-between space-y-8 px-6 py-8 xs:block sm:block md:block">
                <div  className="w-[50%] xs:w-[100%] sm:w-[100%] md:w-[100%] pt-[2rem] space-y-3">
                    <h3  data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className="text-[26px] font-[600] text-gray-800">Core Values</h3>
                    <p  data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className="text-[16px] xs:text-[14px] text-gray-800">Experienced Etsy Specialist adept in the art of crafting successful online businesses. Proficient in every facet of Etsy's ecosystem, from seamless account creation and captivating listings to strategic SEO implementation that boosts visibility. A master of brand creation, I help businesses establish a unique and compelling identity. Offering comprehensive virtual assistance, I manage your Etsy store with finesse, while maximizing reach through targeted ads and innovative marketing strategies. Elevate your Etsy venture with my expertise, and let's carve a path to unrivaled success in the competitive world of e-commerce.</p>
                    <div className="md:flex md:justify-center ">
                        <button  data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className=" py-2 px-6 mt-6 text-[#DE9151] hover:rounded rounded border border-[#DE9151] shadow hover:shadow-md hover:bg-[#DE9151] hover:text-[#fef9f3] text-[16px] font-[500]">
                            <a href="https://www.fiverr.com/virtual390" target="_blank">Hire Now</a>
                        </button>
                        
                    </div>
                </div>
                <div className="w-[40%] xs:w-[100%] sm:w-[100%] md:w-[100%] space-y-4">
                    <div>
                        <div  data-aos="fade-down-left"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className="flex justify-between">
                            <h3>Etsy Consultation</h3>
                            <p>99.0%</p>
                        </div>
                        <div  data-aos="fade-down-left"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className="w-[100%] bg-gray-200 h-[10px] rounded-lg ">
                            <div className="w-[99%] bg-[#DE9151] h-[10px] rounded-lg">
                            </div>
                        </div>
                    </div>
                    <div>
                        <div  data-aos="fade-down-left"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className="flex justify-between">
                            <h3>Etsy Market Research</h3>
                            <p>99.0%</p>
                        </div>
                        <div  data-aos="fade-down-left"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className="w-[100%] bg-gray-200 h-[10px] rounded-lg ">
                            <div className="w-[99%] bg-[#DE9151] h-[10px] rounded-lg">
                            </div>
                        </div>
                    </div>
                    <div>
                        <div  data-aos="fade-down-left"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className="flex justify-between">
                            <h3>Supplier Finding</h3>
                            <p>99.0%</p>
                        </div>
                        <div  data-aos="fade-down-left"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className="w-[100%] bg-gray-200 h-[10px] rounded-lg ">
                            <div className="w-[99%] bg-[#DE9151] h-[10px] rounded-lg">
                            </div>
                        </div>
                    </div>
                    <div>
                        <div  data-aos="fade-down-left"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className="flex justify-between">
                            <h3>Etsy Optimized Product Listing</h3>
                            <p>99.0%</p>
                        </div>
                        <div  data-aos="fade-down-left"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className="w-[100%] bg-gray-200 h-[10px] rounded-lg ">
                            <div className="w-[99%] bg-[#DE9151] h-[10px] rounded-lg">
                            </div>
                        </div>
                    </div>
                    <div>
                        <div  data-aos="fade-down-left"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className="flex justify-between">
                            <h3>Etsy Search Engine Optimization</h3>
                            <p>99.0%</p>
                        </div>
                        <div  data-aos="fade-down-left"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className="w-[100%] bg-gray-200 h-[10px] rounded-lg ">
                            <div className="w-[99%] bg-[#DE9151] h-[10px] rounded-lg">
                            </div>
                        </div>
                    </div>
                    <div>
                        <div  data-aos="fade-down-left"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className="flex justify-between">
                            <h3>Etsy Ads And Marketing</h3>
                            <p>99.0%</p>
                        </div>
                        <div  data-aos="fade-down-left"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className="w-[100%] bg-gray-200 h-[10px] rounded-lg ">
                            <div className="w-[99%] bg-[#DE9151] h-[10px] rounded-lg">
                            </div>
                        </div>
                    </div>
                    <div>
                        <div  data-aos="fade-down-left"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className="flex justify-between">
                            <h3>Brand Uplifting</h3>
                            <p>99.0%</p>
                        </div>
                        <div  data-aos="fade-down-left"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className="w-[100%] bg-gray-200 h-[10px] rounded-lg ">
                            <div className="w-[99%] bg-[#DE9151] h-[10px] rounded-lg">
                            </div>
                        </div>
                    </div>
                    <div>
                        <div  data-aos="fade-down-left"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className="flex justify-between">
                            <h3>Etsy Order Fulfilment</h3>
                            <p>99.0%</p>
                        </div>
                        <div  data-aos="fade-down-left"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className="w-[100%] bg-gray-200 h-[10px] rounded-lg ">
                            <div className="w-[99%] bg-[#DE9151] h-[10px] rounded-lg">
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </div>
    );
}

export default SkilsSection;
