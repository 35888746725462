import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import menu from "../../assets/icons/menu.png"
import { TypeAnimation } from 'react-type-animation';



// const NavData = [
//   {
//     id: 1,
//     title: "Home",
//     link: "/"
//   },
//   {
//     id: 2,
//     title: "About",
//     link: "/",
//   },
//   {
//     id: 3,
//     title: "Skill",
//     link: "/#skill"
//   },
//   {
//     id: 4,
//     title: "Service",
//     link: "/#service"
//   },
//   {
//     id: 5,
//     title: "Blog",
//     link: "/blog"
//   },
// ]


const Navbar = () => {
  const [navBarOpen, setNavBarOpen] = useState(false);

  const handleNavbarMenu = () => {
    setNavBarOpen(!navBarOpen)
  }
  const handleNavbarMenuClose = () => {
    setNavBarOpen(false)
  }


  return (
    <div className='fixed w-[100%] bg-[#1f1e1e]'>
      <nav className=" " >
        <header className="relative w-[100%] bg-[#1f1e1eda]  z-[99] py-4  ">
          <div
            className=" container  flex items-center mx-auto  sm:px-0 xs:px-0 lg:px- "
          >
            <a href="/">
              <h1 className='w-[200px] text-white text-[22px] font-[600] font-[Unbounded]'>ETSY EXPERT</h1>
            </a>
            <div className='flex items-center justify-end w-[90%] xs:justify-start space-x-16 xs:space-x-0 '>
              <div className='flex justify-between space-x-12 2xl:flex  xs:flex  lg:flex  md:hidden sm:hidden xs:hidden'>
                <h4 className='text-[#fef9f3] font-[Poppins] text-[18px] font-[400] hover:text-[#DE9151] hover:delay-300 transition duration-300'><Link to="/home#hero">
                  Home
                </Link>
                </h4>
                <h4 className='text-[#fef9f3] font-[Poppins] text-[18px] font-[400] hover:text-[#DE9151] hover:delay-300 transition duration-300'><Link to="/home#about">
                  About
                </Link>
                </h4>
                <h4 className='text-[#fef9f3] font-[Poppins] text-[18px] font-[400] hover:text-[#DE9151] hover:delay-300 transition duration-300'><Link to="/home#skill">
                  Skill
                </Link>
                </h4>
                <h4 className='text-[#fef9f3] font-[Poppins] text-[18px] font-[400] hover:text-[#DE9151] hover:delay-300 transition duration-300'><Link to="/home#service">
                  Services
                </Link>
                </h4>
                <h4 className=' text-[#fef9f3] font-[Poppins] text-[18px] font-[400] hover:text-[#DE9151] hover:delay-300 transition duration-300'><Link to="/blog">
                  Blog
                </Link>
                </h4>



              </div>
              <div className='flex justify-end  2xl:flex  xs:flex  lg:flex  md:hidden sm:hidden xs:hidden'>
                <button className='navshadow py-2 px-3 font-[Poppins] text-[18px]   hover:text-[#de9151]  hover:delay-300 transition duration-300 text-[#fef9f3]  rounded'><a href="https://www.fiverr.com/virtual390" target="_blank" rel="noopener noreferrer">Get In Touch</a> </button>
              </div>
            </div>

            <div className='space-x-4 flex items-center'>

              <div className='w-[50px] 2xl:hidden xl:hidden lg:hidden md:block sm:block xs:block'>
                <div className='flex justify-end'>
                <img className='' src={menu} onClick={handleNavbarMenu} alt="" />
                </div>
              </div>
            </div>
            {
              navBarOpen &&
              <div className='  ' >

                <div className='absolute bg-[#1f1e1e] left-0 top-12 w-[100%] z-[9999] h-[60vh] space-y-8 py-12 xs:px-12 sm:px-16 md:px-24 px-20 2xl:hidden xl:hidden lg:hidden  '>


                  <button className=' text-white font-[poppins] text-[16px] block  hover:text-[#fef9f3] hover:delay-300 transition duration-300 hover:text-[#a0a0a0]' onClick={handleNavbarMenuClose}  > <Link to="/home">
                    Home
                  </Link></button>
                  <button className=' text-white font-[poppins] text-[16px] block  hover:text-[#fef9f3] hover:delay-300 transition duration-300 hover:text-[#a0a0a0]' onClick={handleNavbarMenuClose}  > <Link to="/home#about">
                    About
                  </Link></button>

                  <button className=' text-white font-[poppins] text-[16px] block  hover:text-[#fef9f3] hover:delay-300 transition duration-300 hover:text-[#a0a0a0]' onClick={handleNavbarMenuClose}  ><Link to="/home#skill">
                    Skill
                  </Link></button>
                  <button className=' text-white font-[poppins] text-[16px] block  hover:text-[#fef9f3] hover:delay-300 transition duration-300 hover:text-[#a0a0a0]' onClick={handleNavbarMenuClose}  ><Link to="/home#service">
                    Services
                  </Link></button>
                  <button className=' text-white font-[poppins] text-[16px] block  hover:text-[#fef9f3] hover:delay-300 transition duration-300 hover:text-[#a0a0a0]' onClick={handleNavbarMenuClose}  ><Link to="/blog">
                    Blog
                  </Link></button>


                  <div className='flex  justify-end    md:block sm:block xs:block'>
                    <button className='w-[100%] navshadow  py-2 px-2 font-[Poppins] text-[18px]   hover:text-[#fef9f3] hover:delay-300 transition duration-300 text-[#DE9151]  rounded' onClick={handleNavbarMenuClose}> <a href="https://www.fiverr.com/virtual390" target="_blank">Get In Touch</a> </button>
                  </div>
                </div>
              </div>
            }

          </div>
        </header >



      </nav >

    </div>
  );
};

export default Navbar;