
import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import er from "../assets/img/er.png"




const RemoveBadReviews = () => {
    AOS.init();


    return (
        <div className="" >
            <div className="py-20 xs:py-6 sm:py-10 w-[80%] m-auto max-w-[1200px] space-y-12">
                <div data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className='space-y-8 '>
                   
                    <img className='w-[100%]' src={er} alt="" />
                </div>
                <div className='pb-4 space-y-8'>
                    <h3 data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="600" data-aos-anchor-placement="top-bottom" className='text-[22px] font-[300]  text-gray-700'>Can we remove bad reviews on Etsy ? How to score quality on Etsy?

                    </h3>
                    <p data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className='text-[16px] font-[400] text-gray-800'>
                        Negative reviews can be disappointing, but they also present an opportunity to showcase your excellent customer service and willingness to address issues. Here's how to effectively manage negative reviews on Etsy:<br /><br />

                        1.  <span className='font-[600]'>*Stay Calm and Professional:*</span>  It's natural to feel frustrated or upset, but always respond to negative reviews in a calm and professional manner. Avoid getting defensive or confrontational.
                        <br /><br />
                        2.  <span className='font-[600]'>*Address the Issue Promptly:*</span> Respond to the review as soon as possible. This shows that you're attentive to customer feedback and committed to resolving the problem.
                        <br /><br />
                        3.  <span className='font-[600]'>*Acknowledge the Customer's Concerns:*</span> Start by acknowledging the customer's feedback and expressing empathy for their experience, even if you disagree with their perspective.
                        <br /><br />
                        4.  <span className='font-[600]'>*Offer Solutions:*</span> Clearly outline how you plan to address the issue. If appropriate, offer a solution such as a replacement, refund, or any other action that can rectify the problem.
                        <br /><br />
                        5.  <span className='font-[600]'>*Apologize:*</span> Apologize for any inconvenience caused, regardless of whether the issue was within your control.<br /><br />

                        6. <span className='font-[600]'>*Keep it Concise:*</span> Your response should be concise and to the point, focusing on the specific issue raised in the review.<br /><br />

                        7.  <span className='font-[600]'>*Take it Offline:*</span>If the issue requires more detailed discussion, encourage the customer to reach out to you through Etsy's messaging system or another preferred method.<br /><br />

                        8.<span className='font-[600]'> *Personalize Your Response:*</span> Tailor your response to the customer's unique situation. Avoid using generic or canned responses.<br /><br />

                        9. <span className='font-[600]'>*Maintain Professional Tone:*</span> Stay positive and professional throughout your response. Your goal is to address the issue and demonstrate your commitment to customer satisfaction.
                        <br /><br />
                        10. <span className='font-[600]'>*Highlight Your Customer Service:*</span> Emphasize any steps you took to prevent similar issues in the future or mention any changes you've made based on the feedback.<br /><br />

                        11. <span className='font-[600]'>*Follow Etsy's Policies:*</span> Ensure that your response follows Etsy's guidelines and policies regarding communication with buyers.<br /><br />

                        12. <span className='font-[600]'>*Encourage Reevaluation:*</span> After resolving the issue, kindly ask the customer to reconsider their review if they are satisfied with the resolution. However, don't pressure them to change their review. <br /><br />

                        13. <span className='font-[600]'>*Learn from Feedback:*</span> Use negative reviews as an opportunity for self-improvement. Consider if there are aspects of your product or service that can be enhanced. <br /><br />

                        14. <span className='font-[600]'>*Monitor and Respond to Follow-Up:*</span> If the customer responds to your initial response, continue the dialogue in a respectful and helpful manner. <br /><br />

                        15. <span className='font-[600]'>*Seek to Resolve Privately:*</span> If possible, try to resolve the issue privately before the customer leaves a public review. This can prevent negative feedback from being posted in the first place. <br /><br />

                        Remember, negative reviews happen to all businesses at some point. The way you handle them can demonstrate your professionalism, dedication to customer satisfaction, and willingness to learn and improve.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default RemoveBadReviews;
