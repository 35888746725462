import React from 'react';
import up from "../../assets/icons/fv.png"
import fv from "../../assets/icons/wp.png"

import { TypeAnimation } from 'react-type-animation';


const Footer = () => {
  return (

      <div className="">
                    <section className="herofooter">
                        <div className="contentfooter space-y-2 text-center">
                            <h2 className='text-[3.5rem] xs:text-[2.1rem]'>ETSY EXPERT</h2>
                            <p>moinuddin@etsyexpert.net</p>
                            <p> Curating distinctive products for an online store that thrives in the diverse world of e-commerce.</p>
                            <p> © EtsyExpert 2023. All Rights Reserved. Crafted with ❤️ by Your EtsyExpert.</p>
                            <div className="flex justify-center space-x-3">
                               <a  target="_blank" href="https://www.fiverr.com/virtual390">
                               <img className="shodow hover:w-[45px] " src={up} alt="" />
                               </a>
                               <a  target="_blank" href="https://www.upwork.com/freelancers/~01dcb01d1bb92eccf9">
                               <img className="shodow hover:w-[45px] " src={fv} alt="" />
                               </a>
                            </div>
                        </div>
                        
                        <div className="waves"></div>
                    </section>


                </div>
       

     
  );
}

export default Footer;
