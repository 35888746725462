import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import Blg1 from "../assets/img/blg1.jpg"
import img1blog from "../assets/img/add.webp"
import sp from "../assets/img/sp.png"



const NotSuspend = () => {
    AOS.init();


    return (
        <div className="" >
            <div className="py-20 xs:py-6 sm:py-10 w-[80%] m-auto max-w-[1200px] space-y-12">
                <div data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className='space-y-8 '>
                 
                    <img className='w-[100%]' src={sp} alt="" />
                </div>
                <div className='pb-4 space-y-8'>
                    <h3 data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className='text-[22px] font-[600] text-gray-700'>How to boost your Etsy sale?</h3>
                    <p data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className='text-[16px] font-[400] text-gray-800'>
                        There are a few things you may do to have your suspended Etsy account reinstated, including fixing the problems that led to the suspension and contacting Etsy's support team. It is vital to review the suspension notice you got and adhere to any instructions offered, albeit these may vary depending on the reason for the suspension and Etsy's regulations. Here's a rough outline of the steps you may take to get your suspended Etsy account back up and running: <br />
                        <br />
                        <span className='font-600'>Check Out The Suspension Letter </span>  1. Please review Etsy's email or notification of suspension carefully. The message should detail the particular infractions that led to the suspension and the next steps you need to take to rectify the situation. <br /> <br />

                        The second step is to <span className='font-[600]'>Address the Issue</span> and take steps to fix the underlying issue that prompted the suspension. Policy breaches, worries over intellectual property, and dissatisfied customers are common grounds for suspension. If your account was temporarily disabled by Etsy because of a breach of their policies, you should check their Seller Policy and make any required adjustments to your store or listings. <br /><br />

                        Etsy Customer Service Email Address: Get in touch with Etsy's support staff using the email or website given in the suspension email. To appeal a suspended account, look for a "Contact Us" link or email address. <br /><br />

                        4. <span className='font-[600]'>Make a Case</span> in Writing: Write a well-organized letter of appeal outlining your efforts to rectify the circumstances that led to your suspension. Justify your claim with specifics and proof, and do so honestly. Emphasize the improvements you've made to your shop and listings and how they comply with Etsy's rules. <br /><br />

                        5. <span className='font-[600]'>Declare and Apologize:</span> Your appeal should include an explanation for the infractions and a statement of your intent to comply with Etsy's policies in the future. Show that you can follow instructions, and that you care about giving them a good experience. <br /><br />

                        Wait for a Reaction #6 Wait for Etsy's support staff to consider your appeal after filing it. Be patient, as this might take some time. <br /><br />

                        <span className='font-[600]'>Follow Up </span>7. If you haven't heard back about your appeal within a fair amount of time, you might want to try sending a follow-up communication. Don't bombard the system with messages all at once, since it might slow things down. <br /><br />

                        8. <span className='font-[600]'>Recommendations to be Carried Out </span>Respond quickly to any further instructions for reactivating your account that may be sent by the Etsy support staff. <br /><br />

                        Keep up with the News #9. While you wait for your account to be restored, make sure you're familiar with Etsy's standards and guidelines so that you can comply with them once your shop is back up and running. <br /><br />

                        Keep in mind that Etsy's standards and policies dictate whether or not your account will be suspended. Your chances of having your Etsy account reinstated are higher if you submit a well-written appeal, accept responsibility for any infractions, and show that you are committed to improving your shop's compliance.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default NotSuspend;
