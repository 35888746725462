
import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import es from "../assets/img/es.png"



const EnoughSales = () => {
    AOS.init();


    return (
        <div className="" >
            <div className="py-20 xs:py-6 sm:py-10 w-[80%] m-auto max-w-[1200px] space-y-12">
                <div data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className='space-y-8 '>
                  
                    <img className='w-[100%]' src={es} alt="" />
                </div>
                <div className='pb-4 space-y-8'>
                    <h3 data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="600" data-aos-anchor-placement="top-bottom" className='text-[22px] font-[600]  text-gray-700'>Why is your Etsy shop not getting enough sales? How to get sales on Etsy ?</h3>
                    <p data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="600" data-aos-anchor-placement="top-bottom" className='text-[16px] font-[400] text-gray-800'>
                        There could be several reasons why you're not getting good sales on Etsy. Selling on Etsy requires a combination of factors, from product quality and visibility to effective marketing and customer engagement. Here are some common factors to consider: <br /><br />

                        1. *Product Quality and Descriptions:*
                        - Are your products of high quality? Customers are more likely to buy if they feel they're getting value for their money. <br />
                        - Are your product descriptions detailed and accurate? Clear and comprehensive descriptions help customers understand what they're buying. <br /><br />

                        2. *Competitive Market:*
                        - Depending on your niche, you might face competition from other sellers. Make sure your products stand out in terms of quality, uniqueness, and presentation. <br /><br />

                        3. *Pricing:*
                        - Are your prices competitive within your niche? If your prices are too high, potential buyers might be discouraged. If they're too low, buyers might perceive the quality as low. <br /><br />

                        4. *Photography:*
                        - High-quality, well-lit, and visually appealing photos are crucial. Good photography showcases your products and helps customers visualize what they're purchasing. <br /><br />

                        5. *SEO and Keywords:*
                        - Have you optimized your product titles, tags, and descriptions with relevant keywords? Proper SEO helps your products appear in search results when customers look for items like yours.
                        <br /><br />
                        6. *Customer Reviews and Ratings:*
                        - Positive reviews build trust. Make sure you provide excellent customer service and encourage buyers to leave reviews. <br /><br />

                        7. *Marketing and Promotion:*
                        - Are you actively promoting your Etsy shop? Utilize social media, email marketing, and other platforms to reach a wider audience. <br /><br />

                        8. *Shop Policies:*
                        - Transparent and well-defined shop policies instill confidence in buyers. Make sure you clearly communicate shipping, returns, and other policies. <br /><br />

                        9. *Variety and Inventory:*
                        - Offering a variety of products can attract a broader range of customers. Regularly update your shop with new items to keep customers engaged. <br /><br />

                        10. *Engagement:*
                        - Respond to customer inquiries promptly and professionally. Engage with customers in a friendly and helpful manner. <br /><br />

                        11. *Etsy Search Algorithm:*
                        - Etsy's search algorithm considers factors like listing quality, relevance, and engagement. Keep your listings up to date and focus on increasing customer engagement. <br /><br />

                        12. *Seasonal Trends:*
                        - Some products might sell better during certain times of the year. Consider whether your products align with seasonal trends. <br /><br />

                        13. *Shop Appearance:*
                        - Is your shop well-organized and visually appealing? A clean and attractive shop layout can encourage browsing and buying. <br /><br />

                        14. *Shipping Costs and Times:*
                        - Transparent and reasonable shipping costs and estimated delivery times are important for customer satisfaction. <br /><br />

                        15. *Target Audience:*
                        - Ensure your products are tailored to a specific target audience. Understanding your ideal customers can help you create products that resonate with them. <br /><br />

                        Assessing these factors and making improvements where needed can help increase your chances of getting more sales on Etsy. It's important to be patient and persistent, as building a successful Etsy shop takes time and effort. Regularly analyze your shop's performance and make adjustments based on customer feedback and market trends.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default EnoughSales;
