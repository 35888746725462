import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import WhatIdoCard from "./whatIdocard";
import Blg1 from "../assets/img/blg1.jpg"

const BlogCard = ({title, para, link, img}) => {
    return (
        <Link to={link}>
        <div className=" bg-white border border-gray-200 rounded-lg shadow-xs hover:shadow-xl h-[auto] " data-aos-easing="ease-in-out" data-aos="fade-left"  data-aos-once="false"  data-aos-mirror="true"  data-aos-duration="300">
            <a href="">
                <img
                    className="rounded-t-lg w-[100%] h-[180px]"
                    src={img}
                    alt=""
                    
                />
            </a>
            <div className="p-5 flex justify-between flex-col h-[360px] xs:h-[380px]">
                <a href="#">
                    <h5 className="mb-2 text-2xl xs:text-[18px] xs:leading-6 font-[600]  text-gray-700 ">
                      {title}
                    </h5>
                </a>
                <p className="mb-3 font-normal xs:text-[14px] text-gray-700 ">
                  {para}
                </p>
                <a
                    href=""
                    className="w-[130px] inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-[#DE9151] rounded-lg hover:bg-[white] hover:border hover:border-[#DE9151] hover:text-[#DE9151] focus:ring-4 "
                >
                    Read more
                    <svg
                        className="w-3.5 h-3.5 ml-2"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 10"
                    >
                        <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M1 5h12m0 0L9 1m4 4L9 9"
                        />
                    </svg>
                </a>
             
            </div>
        </div>
        </Link>
    );
}

export default BlogCard;
