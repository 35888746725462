import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import eds from "../assets/img/ph.png"






const ProductHunting = () => {

    AOS.init();

    return (
        <div className="" >
            <div className="py-20 xs:py-6 sm:py-10 w-[80%] m-auto max-w-[1200px] space-y-12">
                <div  data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className='space-y-8 '>
                  
                    <img className='w-[100%]' src={eds} alt="" />
                </div>
                <div className='pb-4 space-y-8'>
                    <h3  data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className='text-[22px] font-[600] text-gray-700'>Product hunting and supplier finding on Etsy shop.</h3>
                    <p  data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className='text-[16px] font-[400] text-gray-800'>
                        When looking for suppliers for dropshipping on Etsy, it's important to choose reliable and reputable partners that offer quality products and timely shipping. Keep in mind that Etsy has specific guidelines for dropshipping, so make sure to thoroughly understand and adhere to their policies. Here are a few options to consider: <br /><br />

                        1.  <span className='font-[600]'>*Printful:*</span> Printful offers a wide range of customizable products, including apparel, accessories, and home goods. They integrate well with Etsy and allow you to add your designs to their products. <br /><br />

                        2. <span className='font-[600]'> *Printify:*</span> Similar to Printful, Printify offers a variety of products that can be customized with your designs. They have a network of print providers to choose from, allowing you to find the best fit for your niche. <br /><br />

                        3. <span className='font-[600]'>*AOP+ (All Over Print):*</span>  If you're interested in all-over print products like clothing and accessories, AOP+ specializes in this area and can help you create unique and eye-catching designs. <br /><br />

                        4. <span className='font-[600]'>*SPOD (Spreadshirt Print-On-Demand):*</span>  SPOD offers fast printing and shipping times, making it a good option for sellers who want to provide quick delivery to their customers. <br /><br />

                        5. <span className='font-[600]'>*T-Pop:*</span>  T-Pop focuses on eco-friendly and sustainable print-on-demand products. If you have an environmentally conscious audience, this might be a suitable option. <br /><br />

                        6. <span className='font-[600]'>*TeePublic:*</span> TeePublic offers a variety of print-on-demand products, particularly focusing on apparel and accessories with creative designs. <br /><br />

                        7.<span className='font-[600]'>*Gooten:*</span>  Gooten provides a range of customizable products and integrates well with Etsy. They offer products beyond apparel, including home decor and accessories. <br /><br />

                        8. <span className='font-[600]'>*Print Aura:*</span> Print Aura offers direct integration with Etsy and provides print-on-demand services for apparel, accessories, and more. <br /><br />

                        9.<span className='font-[600]'>*CustomCat:*</span>  CustomCat offers a wide variety of customizable products and has integration with Etsy, making it easier to manage your listings. <br /><br />

                        10.<span className='font-[600]'>*Pillow Profits:* </span> If you're interested in dropshipping footwear and home decor items, Pillow Profits specializes in these categories. <br /><br />

                        Before choosing a supplier, consider the following factors: <br /><br />

                        -  <span className='font-[600]'>*Product Quality:* <br /> </span> Ensure that the supplier offers high-quality products that align with your brand and customer expectations.
                        - *Shipping Times:* Look for suppliers with reasonable shipping times to ensure customer satisfaction.
                        - *Integration:* Check if the supplier integrates well with Etsy and offers features like automatic order fulfillment and syncing inventory.
                        - *Customer Support:* A responsive and helpful customer support team can make a significant difference in managing any issues that arise.
                        - *Pricing:* Compare the pricing of different suppliers to find one that offers competitive rates without compromising quality. <br /><br />

                        Remember to thoroughly research each supplier, read reviews from other dropshippers, and test their services before fully committing to them. Additionally, stay up-to-date with Etsy's policies and guidelines for dropshipping to ensure compliance.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default ProductHunting;
