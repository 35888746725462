
import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import Blg1 from "../assets/img/enc.png"


const IncreaseSales = () => {
    AOS.init();

    return (
        <div className="" >
            <div className="py-20 xs:py-6 sm:py-10 w-[80%] m-auto max-w-[1200px] space-y-12">
                <div data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className='space-y-8 '>
                  
                    <img className='w-[100%]' src={Blg1} alt="" />
                </div>
                <div className='pb-4 space-y-8'>
                    <h3 data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className='text-[22px] font-[600] text-gray-700'>How to increase sales on an Etsy shop?
                    </h3>
                    <p data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="600" data-aos-anchor-placement="top-bottom" className='text-[16px] font-[400] text-gray-800'>
                        Increasing sales on Etsy requires a combination of effective strategies, dedication, and a willingness to adapt. Here are some actionable tips to help you boost your sales on Etsy: <br /><br />

                        1. <span className='font-[600]'>*Optimize Listings:*</span>
                        - Use descriptive and relevant keywords in titles, tags, and descriptions to improve visibility in search results.
                        - High-quality photos that showcase your products from various angles and in different settings can attract more buyers.<br /><br />

                        2.  <span className='font-[600]'>*Offer a Variety of Products:*</span>
                        - Expand your product range to cater to a wider audience and increase the likelihood of repeat purchases.<br /><br />

                        3.  <span className='font-[600]'>*Provide Exceptional Customer Service:*</span>
                        - Respond to inquiries promptly and professionally.
                        - Process orders quickly and accurately.
                        - Address any issues or concerns with empathy and efficiency.<br /><br />

                        4.  <span className='font-[600]'>*Offer Special Promotions:*</span>
                        - Run occasional sales, discounts, or bundles to attract more buyers and encourage larger orders.<br /><br />

                        5.  <span className='font-[600]'>*Leverage Social Media:*</span>
                        - Promote your Etsy shop on platforms like Instagram, Facebook, Pinterest, and Twitter.
                        - Share high-quality images, behind-the-scenes content, and stories related to your products.
                        <br /><br />
                        6. <span className='font-[600]'>*Use Etsy Ads (Formerly Promoted Listings):* </span>
                        - Invest in Etsy Ads to have your listings appear at the top of search results, gaining better visibility.
                        <br /><br /> 
                        7.  <span className='font-[600]'>*Optimize Shipping:*</span>
                        - Offer competitive shipping rates and options to appeal to buyers.
                        - Provide accurate estimated delivery times to manage expectations.
                        <br /><br />
                        8.  <span className='font-[600]'>*Create a Strong Brand Identity:*</span>
                        - Develop a cohesive brand presence with a unique shop banner, logo, and consistent packaging.
                        <br /><br />
                        9.<span className='font-[600]'> *Encourage Reviews:* </span>
                        - Ask satisfied customers to leave reviews. Positive reviews can build trust and attract more buyers.
                        <br /><br />
                        10.  <span className='font-[600]'>*Implement Cross-Promotion:*</span>
                        - Mention other relevant products in your listings to encourage buyers to explore more of your shop.
                        <br /><br />
                        11.  <span className='font-[600]'>*Engage with Trends:*</span>
                        - Create products that align with current trends or seasonal events.
                        <br /><br />
                        12. <span className='font-[600]'> *Participate in Etsy Teams:*</span>
                        - Join Etsy teams related to your niche to connect with other sellers and learn from their experiences.
                        <br /><br />
                        13.  <span className='font-[600]'>*Offer Customization:*</span>
                        - Allow buyers to request customizations, giving them a unique and personalized shopping experience.
                        <br /><br />
                        14. <span className='font-[600]'> *Frequent Shop Updates:*</span>
                        - Regularly add new items or update existing ones to keep your shop fresh and interesting.
                        <br /><br />
                        15. <span className='font-[600]'> *Provide Clear Policies:*</span>
                        - Outline your shop's policies regarding returns, exchanges, and shipping on your storefront.
                        <br /><br />
                        16.  <span className='font-[600]'>*Utilize Email Marketing:*</span>
                        - Build an email list and send newsletters featuring updates, promotions, and new products.
                        <br /><br />
                        17. <span className='font-[600]'> *Optimize Pricing:*</span>
                        - Price your products competitively while considering factors like materials, time, and competition.    
                        <br /><br />
                        18. <span className='font-[600]'> *Attend Craft Fairs or Markets:*</span>
                        - Participate in local craft fairs or markets to introduce your products to a wider audience.
                        <br /><br />
                        Remember that building a successful Etsy shop takes time and effort. Continuously analyze your results, experiment with different strategies, and adapt based on what works best for your unique shop and target audience.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default IncreaseSales;
