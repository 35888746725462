import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import Blg1 from "../assets/img/blg1.jpg"
import img1blog from "../assets/img/add.webp"
import bs from "../assets/img/bs.png"






const BoostYourEtsy = () => {

    AOS.init();
  

    return (
        <div className="" >
            <div className="py-20 xs:py-6 sm:py-10 w-[80%] m-auto max-w-[1200px] space-y-12">
                <div data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className='space-y-8 '>
                    
                    <img className='w-[100%]' src={bs} alt="" />
                </div>
                <div className='pb-4 space-y-8'>
                    <h3 data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="600" data-aos-anchor-placement="top-bottom" className='text-[22px] font-[600] text-gray-700'>How to boost your Etsy sale?</h3>
                    <p data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="600" data-aos-anchor-placement="top-bottom" className='text-[16px] font-[400] text-gray-800'>
                    If you're wondering how to boost Etsy sales, it's essential to understand that learning how to boost Etsy sales requires a multifaceted approach. <br /> First and foremost, optimizing your product listings is crucial. Ensure your product titles, descriptions, and tags are all relevant and engaging. Additionally, how to boost Etsy sales involves leveraging social media platforms to increase your shop's visibility. <br /> Engage with your audience by posting regularly and showcasing your products in creative ways. Don't forget the importance of customer reviews – positive feedback can greatly impact how to boost Etsy sales by building trust. Moreover, collaborations with influencers and bloggers can also teach you how to boost Etsy sales effectively. <br /> Remember, mastering how to boost Etsy sales is an ongoing journey that involves continuous learning and adaptation to the ever-changing e-commerce landscape.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default BoostYourEtsy;
