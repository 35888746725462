import React, { useRef, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import IMg1 from "../assets/icons/sign.png"

const ClientData = [
    {
        id: 1,
        title: "Rateem",
        subtitle: " The seller went above and beyond the expectations with a brand new Etsy account we made the first sale in 1 week. This gig is extremely recommended and excellent communication thank you. ",
    },
    {
        id: 2,
        title: "Rtandalone",
        subtitle: "I'm very happy with the final results! the store looks incredible, great communication. HIGHLY recommend",
    },
    {
        id:3,
        title:"Ranoriilambert",
        subtitle:"If you are looking for the ultimate side hustle, get you an Etsy store & hire this guy as your VA . I have been working with him for over six months, and I am still satisfied.  ",
    },
    {
        id:4,
        title:"Rsgf3423",
        subtitle:"The seller was very patient and understanding of my situation. The title, Keywords, and description are very well written, with SEO skills well demonstrated and I believe they'll translate to a good ranking. I am happy with the delivery and highly recommend them to any Etsy sellers who need help",
    },
    {
        id:5,
        title:"Rghriot2",
        subtitle:"New to POD and Etsy I was looking for some help with my listings to give me the best opportunity to rank. I also learnt from the information provided about the process of SEO on Etsy. Thanks",
    },

]

const TestimonialsSection = () => {
    AOS.init();

    return (
        <div className="">
            <div className="space-y-6 p-2 overflow-hidden ">
                <div  data-aos="fade-down"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className=" text-center bgimgtest shadow w-[100%] py-8 px-8 space-y-3">
                    <h3 className="text-[32px] xs:text-[1.2rem] font-[700] text-gray-800 tracking-[2px] m-auto w-[30%] xs:w-[200px] sm:w-[200px] md:w-[200px] border-b border-[#DE9151] font-[Unbounded]">Testimonials</h3>
                    {/* <hr className="w-[170px] m-auto h-[3px] bg-[#DE9151]"/> */}
                    <p className="text-[16px] font-[500] text-gray-800">Outstanding Etsy VA, boosted my shop's success with expert support!</p>
                </div>
                <div className="shadow rounded">
                    <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                            delay: 3500,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Autoplay, Pagination]}
                        className="mySwiper"
                    >
                        {
                            ClientData.map((item) => (
                                <SwiperSlide className="">
                                    <div className='w-[60%] xs:w-[88%] m-auto py-8 space-y-2'>
                                        <div className='flex justify-center pb-3'>
                                            <div className='w-[100px] bg-[#DE9151] p-5 xs:p-3'>
                                                <img className='' src={IMg1} alt="" />
                                            </div>
                                        </div>
                                        <h3 className='text-[24px]  font-[600] text-gray-800 italic'>{item.title}</h3>
                                        <p className="text-gray-800 text-[16px] xs:text-[14px]  font-[600] italic"> {item.subtitle}  </p>
                                    </div>
                                </SwiperSlide>
                            ))
                        }

                    </Swiper>
                </div>


            </div>



        </div>
    );
}

export default TestimonialsSection;
