import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import Blg1 from "../assets/img/sus.jpg"
import img1blog from "../assets/img/add.webp"



const ShopGetSuspension = () => {
    AOS.init();


    return (
        <div className="" >
            <div className="py-20 xs:py-6 sm:py-10 w-[80%] m-auto max-w-[1200px] space-y-12">
                <div data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className='space-y-8 '>
                  
                    <img className='w-[100%]' src={Blg1} alt="" />
                </div>
                <div className='pb-4 space-y-8'>
                    <h3 data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className='text-[22px] font-[600]  text-gray-700'>Why does your Etsy shop get suspension and how to avoid it?</h3>
                    <p data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className='text-[16px] font-[400] text-gray-800'>
                        Avoiding policy violations on Etsy is crucial to maintaining a successful and compliant shop. Here are some tips to help you avoid violating Etsy's policies: <br /><br />

                        1.  <span className='font-[600]'>*Read and Understand Policies:*</span> Familiarize yourself with Etsy's Seller Policy, Listing Policy, and all other relevant policies. Make sure you know what's allowed and what isn't. <br /><br />

                        2.  <span className='font-[600]'>*Accurate Item Descriptions:*</span> Provide accurate and honest descriptions for your products. Avoid making false claims or misrepresenting your items. <br /><br />

                        3.  <span className='font-[600]'>*Original and Handmade Items:*</span> If you're selling handmade items, ensure they are genuinely handmade by you or your team. Reselling mass-produced items is not allowed in handmade categories. <br /><br />

                        4.  <span className='font-[600]'>*Intellectual Property:*</span>Respect intellectual property rights. Don't use copyrighted images, logos, characters, or trademarks without proper authorization. <br /><br />

                        5.  <span className='font-[600]'>*Prohibited Items:*</span> Be aware of items that are prohibited on Etsy, such as weapons, drugs, hazardous materials, and items that promote hate speech. <br /><br />

                        6.  <span className='font-[600]'>*Accurate Tags and Titles:*</span> Use accurate and relevant tags, titles, and categories. Don't use unrelated or misleading keywords to manipulate search results. <br /><br />

                        7. <span className='font-[600]'> *Shipping and Delivery:*</span> Clearly state your shipping policies, processing times, and estimated delivery dates. Fulfill orders promptly and communicate with buyers if there are delays. <br /><br />

                        8.  <span className='font-[600]'>*Communication:*</span> Respond to buyer inquiries and messages in a timely and professional manner. Good communication helps build trust. <br /><br />

                        9. <span className='font-[600]'> *Honest Photos:*</span> Use clear and accurate photos that represent your items as they are. Avoid using images that mislead buyers about the product's appearance. <br /><br />

                        10.  <span className='font-[600]'>*Customer Reviews:*</span> Encourage positive feedback by providing excellent customer service. Address any issues or negative reviews constructively. <br /><br />

                        11. *Pricing Transparency:* <span className='font-[600]'></span> Clearly display your item prices, shipping costs, and any additional fees.<br /><br />

                        12.  <span className='font-[600]'>*Environmental Claims:*</span> If you're making environmental claims about your products (e.g., eco-friendly, sustainable), ensure they are accurate and supported.<br /><br />

                        13. <span className='font-[600]'>*Privacy and Personal Information:* </span>  Protect buyer's personal information and follow privacy regulations when handling customer data.<br /><br />

                        14. *Customs and Taxes:* Clearly state any customs fees or taxes that buyers may incur for international orders.<br /><br />

                        15. <span className='font-[600]'> *Listing Variations:* </span> If you're offering different variations of a product (e.g., size, color), make sure your listing accurately represents all options.<br /><br />

                        16. *Community Standards:* Respect Etsy's community standards and treat fellow sellers and buyers with professionalism and courtesy.<br /><br />

                        17. <span className='font-[600]'>*Intellectual Property Claims:* </span>   Respond appropriately if you receive a claim of intellectual property infringement. Follow Etsy's instructions for resolving such claims.<br /><br />

                        18.<span className='font-[600]'>*Account Security:*</span>   Keep your account secure by using strong passwords and regularly updating them. Be cautious about sharing account information.<br /><br />
 
                        19.<span className='font-[600]'>*Fees and Payments:*</span>   Understand Etsy's fee structure and ensure your payment information is accurate to avoid payment processing issues.<br /><br />

                        20. <span className='font-[600]'>*Stay Updated:*</span> Keep yourself updated on any changes to Etsy's policies. These changes are communicated through emails, notifications, and announcements on the platform.<br /><br />

                        By following these guidelines and always acting with honesty and transparency, you can create a successful and compliant Etsy shop that provides a positive experience for both buyers and sellers. If you're unsure about a specific situation, don't hesitate to contact Etsy's customer support for clarification and guidance.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default ShopGetSuspension;
