import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import img1blog from "../assets/img/img1.png"




const EtsyShopBlog = () => {

    AOS.init();

    return (
        <div className="" >
            <div className="py-20 xs:py-6 sm:py-10 w-[80%] m-auto max-w-[1200px] space-y-12">
                <div data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="300" data-aos-anchor-placement="top-bottom" className='space-y-8 '>
                 
                    <img className='w-[100%]' src={img1blog} alt="" />
                </div>
                <div className='pb-4 space-y-8'>
                    <h3 data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="600" data-aos-anchor-placement="top-bottom" className='text-[22px] font-[600]  text-gray-700'>How to create an etsy Shop? </h3>
                    <p data-aos="fade-down-right"  data-aos-once="false" data-aos-mirror="true" data-aos-duration="600" data-aos-anchor-placement="top-bottom" className='text-[16px] font-[400] text-gray-800'>
                        While opening an Etsy shop is a simple procedure, there are a few obstacles you may encounter. Follow this detailed tutorial to open an Etsy shop and avoid the most frequent pitfalls:
                        How to Open an Etsy Shop in 7 Easy Steps <br /><br />

                        Create an Account: If you haven't already, create an account on Etsy. Sign in if you have an existing account. <br />

                        Launch your online store by clicking your user icon (top right) and selecting "Sell on Etsy" from the resulting menu. To start selling on Etsy, select the button labeled "Open Your Etsy Shop." <br /><br />

                        Pick a Shop Name: Your shop's name will serve as the public face of your business. Put some thought into making it special and applicable to your product. Etsy will do the research to make sure the name is available. <br /><br />

                        Pick Your Shop's Language and Currency! Set your store's language and currency here! <br />

                        Etsy has two options for listing your items: "Manual" and "Automatic." The listing charge for the manual plan is $0.20 per item, and you are responsible for handling renewals yourself. The auto-renewing plan is similarly priced at $0.20 per item, but it renews on its own. <br /><br />

                        Make Listings: Post your wares for sale. Take good pictures, write in-depth descriptions, and decide on a pricing. Make sure your writing is interesting and descriptive. <br />

                        Get Paid: Link Your Store to a Payment Gateway. Since Etsy Payments accepts several payment options, it is highly recommended. <br />

                        Pick your shipping preferences and create your profiles. Shipping options and costs can be tailored to the customer's needs. <br /> <br />

                        Define your store's policies, such as those pertaining to returns, exchanges, and shipping. Customers' expectations may thus be more accurately met.  <br />

                        Verify all information is correct before pressing the "Publish" button. When everything seems good, hit the "Open Your Shop" button. <br />

                        Challenges Most People Face When Opening an Etsy Shop <br />

                        You may not be able to use the name you choose for your store. In case your top choice is already taken, have a couple backups ready. <br /><br />

                        Professional-grade product photography is crucial. If you're having trouble with your photographs, you might want to consult a professional or at least educate yourself on the fundamentals of photography. <br /><br />

                        Descriptions of Products Should Be Precise and Clear. Don't give information that might be misconstrued. <br />

                        Setting pricing correctly is a challenging task. Find out what other sellers are charging for similar items on Etsy so you can set your own prices while still making a profit. <br />

                        Shipping: Estimating shipping prices and choices precisely might be difficult. Think about things like shipping, weight, and where you're sending it. <br />

                        Taxes, copyright, and trademark difficulties are just some of the legal considerations you'll need to be aware of when selling. <br />

                        Your store's branding should be consistent and appealing to attract customers. Create a banner, logo, and profile image for your store that stands out. <br />

                        As a customer service representative, you should always be well-prepared to deal with any questions, concerns, or orders that come your way. <br />

                        Clear and reasonable store regulations can help avoid conflicts with consumers. <br />

                        Tags and Search Engine Optimization (SEO) are two factors that can increase the number of people who see your listings in search results. <br /> <br />

                        Keep in mind that difficulties are inevitable but that they may be overcome with time and effort. Take use of Etsy's seller's manual and discussion boards to pick the brains of seasoned pros and avoid common pitfalls.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default EtsyShopBlog;
